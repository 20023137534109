<template>
  <div class="freelancing-network">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="
        background: url('/img/freelancer-network/header-freelance-network.jpg');
        background-size: cover;
      "
    >
      <div class="viewport">
        <h1>Connect with a unique pool of freelance IT professionals</h1>
        <p>
          Enable and empower your business and find the competence you need for on-demand, and
          short-term projects.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="benefits">
        <h3>Benefits at a glance</h3>
        <p>
          Our fully qualified freelancers are ideal for short-term project work, unexpected peaks,
          or for when you need specific expertise that you don’t possess internally. Our freelancing
          projects are often conducted remotely, but we can also assign suitable individuals for
          on-site projects.
        </p>
      </div>
      <div class="benefit-boxes d-flex justify-content-between flex-wrap">
        <div class="box d-flex align-items-center">
          <img src="/img/icons/icons8-filter-100.png" alt="screening" />
          All candidates are screened to measure expertise, professionalism, and communication
          skills.
        </div>

        <div class="box d-flex align-items-center">
          <img src="/img/icons/icons8-time-to-pay-100.png" alt="clock icon" />
          Enjoy financial flexibility and pay per hour, on-demand and without the need for long-term
          contracts.
        </div>

        <div class="box d-flex align-items-center">
          <img src="/img/icons/icons8-resume-100.png" alt="clock icon" />
          Only receive the most suitable candidates based on our understanding of your needs.
        </div>

        <div class="box d-flex align-items-center">
          <img src="/img/icons/icons8-video-message-100.png" alt="camera icon" />
          Communicate directly with your freelancer through your preferred collaboration tools.
        </div>

        <div class="box d-flex align-items-center">
          <img src="/img/icons/icons8-growing-money-100.png" alt="clock icon" />
          Benefit from close alignment to your business culture, ways of working and time zone.
        </div>

        <div class="box d-flex align-items-center">
          <img src="/img/icons/icons8-invoice-100.png" alt="invoice icon" />
          Avoid administrative headaches with our time reporting system and direct invoicing.
        </div>
      </div>
      <div class="call-to-action-boxes d-flex justify-content-between">
        <div
          class="box"
          style="background: url('/img/homepage/join-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Nearshoring or direct hiring?</h3>
            <p>
              If you need something more permanent or long term, we can source and present talent
              that’s perfectly suited to your needs.
            </p>
            <router-link to="/services" class="btn btn-primary">Learn more</router-link>
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url('/img/homepage/become-freelancer.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Are you a freelancer?</h3>
            <p>
              As one of our freelancers, you’ll have access to our wide range of clients and
              opportunities to further your skills as a professional.
            </p>
            <router-link to="/freelancers" class="btn btn-primary">Learn more</router-link>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'IT Professional Freelancing Network | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.freelancing-network {
  .benefits {
    padding: 70px 0;
    h3 {
      text-align: center;
      font-size: 38px;
      font-weight: 700;
    }
    p {
      text-align: center;
      width: 75%;
      margin: 0 auto;
    }
  }
  .benefit-boxes {
    .box {
      width: 32%;
      margin-bottom: 40px;
      img {
        width: 100px;
        height: 100px;
        margin-right: 12px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .freelancing-network {
    .benefits {
      h3 {
        font-size: 2rem;
      }
      p {
        width: 100%;
      }
    }
    .benefit-boxes {
      display: block !important;
      .box {
        width: 100%;
      }
    }
  }
}
</style>
